<template lang="pug">
sidebar-accordion(v-if="isThemeKit" :title="$t('customTheme.savedStyle')" :isOverflow="false")
  om-select#themeDefaults.mt-2(
    v-model="selectedThemeVersion"
    size="medium"
    @input="changeThemeVersion"
    :options="themeVersions"
    @edit="edit"
    @remove="remove"
  )
  .button-holder.d-flex.flex-row.mt-3
    om-button.mr-2(
      :disabled="isCustomThemeVersion || !isElementModifiedByUser"
      primary
      medium
      @click="update"
    ) {{ $t('customTheme.update') }}
    om-button(secondary @click="saveAsNew") {{ $t('customTheme.saveAsNew') }}
</template>

<script>
  import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
  import itemMixin from '@/editor/mixins/item';
  import { merge } from 'lodash-es';
  import { replaceQuillContent } from '@om/custom-theme-styles/src/htmlUtil';
  import { track } from '@/services/xray';

  const frameStore = () => {
    const workspace = document.getElementById('workspaceFrame');
    if (!workspace) {
      return;
    }
    return workspace.contentWindow.om?.store;
  };

  export default {
    mixins: [itemMixin],
    data() {
      return {
        localModifiedElements: [],
        themeDefaults: [],
        themeVersions: [],
        selectedThemeVersion: {},
      };
    },
    computed: {
      ...mapState(['selectedElement']),
      ...mapGetters([
        'accountFeatures',
        'isThemeKit',
        'isSteveMode',
        'modifiedElements',
        'isUniversal',
      ]),

      isElementModifiedByUser() {
        return this.getLocalModifiedElement?.value || this.getModifiedElement?.value;
      },

      getLocalModifiedElement() {
        return this.localModifiedElements.find((elem) => elem.uid === this.selectedElement.uid);
      },

      getModifiedElement() {
        return this.modifiedElements.find((elem) => elem.uid === this.selectedElement.uid);
      },

      isCustomThemeVersion() {
        return this.selectedThemeVersion.key === 'custom';
      },
    },
    mounted() {
      if (!this.isThemeKit) return;
      this.setCurrentThemeVersion();
      this.localModifiedElements = this.modifiedElements;

      this.$bus.$on('element-property-changed', (modifiedElements) => {
        this.localModifiedElements = modifiedElements;
      });

      this.$bus.$on('theme-style-saved', async (success) => {
        if (success) {
          this.resetModification();
          await this.setCurrentThemeVersion();

          this.$nextTick(() => {
            this.setAllOccurrences(
              this.selectedElement.type,
              this.selectedElement.data.themeVersion,
            );
          });
        }

        this.notify(success);
      });

      this.$bus.$on('custom-style-modified', ({ newName }) => {
        if (newName) {
          this.selectedElement.data.themeVersion = newName;
        }
        this.setCurrentThemeVersion();
      });
    },
    beforeDestroy() {
      this.$bus.$off('element-property-changed');
      this.$bus.$off('theme-style-saved');
      this.$bus.$off('custom-style-modified');
    },
    methods: {
      ...mapActions(['fetchThemeStyles']),
      ...mapMutations([
        'modifyElementByUid',
        'changeFormManagerVisibility',
        'changeThemeVersionForElement',
        'setElementStylesChanged',
      ]),

      edit(option) {
        this.changeFormManagerVisibility({ show: 'editCustomStyle', meta: option });
      },
      remove(option) {
        this.changeFormManagerVisibility({ show: 'deleteCustomStyle', meta: option });
      },
      async setCurrentThemeVersion() {
        await this.setThemeValue();
        const currentElementThemeVersion = this.selectedElement?.data?.themeVersion;
        const foundThemeVersion = this.themeVersions.find(
          (e) => e.key === currentElementThemeVersion,
        );

        this.selectedThemeVersion =
          foundThemeVersion || this.themeVersions[this.themeVersions.length - 1];

        if (this.isElementModifiedByUser && !this.isCustomThemeVersion) {
          const modificationText = this.$i18n.t('customTheme.modified');
          const modifiedText = foundThemeVersion.value.concat(` ${modificationText}`);
          foundThemeVersion.value = modifiedText;
        }
      },

      async setThemeValue() {
        const { themeDefaults, themeOptions } = await this.fetchThemeStyles();

        this.themeDefaults = themeDefaults;
        themeOptions.forEach((option) => {
          if (option.key === 'custom') return;

          if (option.userCreated === true || this.isSteveMode) {
            option.deletable = true;
            option.editable = true;
          } else {
            option.deletable = false;
            option.editable = false;
          }
        });
        this.themeVersions = themeOptions;
      },

      update() {
        const themeVersion = this.selectedElement?.data?.themeVersion;
        const suggestedName = themeVersion !== 'custom' ? themeVersion : '';
        const payload = {
          uid: this.selectedElement.uid,
          version: suggestedName,
        };

        this.$bus.$emit('setThemeStyle', payload);
        this.resetModification();

        this.setElementStylesChanged(true);
      },

      setAllOccurrences(elementType, themeVersion) {
        const workspaceStore = frameStore();
        const elements = workspaceStore.state.template.elements;
        const elementsWithSameThemeVersion = elements
          .filter(({ type, data }) => type === elementType && data.themeVersion === themeVersion)
          .filter((elements) => elements.uid !== this.selectedElement.uid);
        const defaultStyle = this.themeDefaults.find((e) => e.name === themeVersion);

        elementsWithSameThemeVersion.forEach((element) => {
          this.changeThemeVersionForElement({ element, themeVersion, defaultStyle });
          this.resetModification(element.uid);
        });
      },

      saveAsNew() {
        this.changeFormManagerVisibility({ show: 'addNewStyle' });
      },

      resetModification(uid = null) {
        this.$nextTick(() => {
          this.localModifiedElements.forEach((elem) => {
            if (elem.uid === this.selectedElement.uid || uid === elem.uid) {
              elem.value = false;
            }
          });
          this.modifyElementByUid({ uid: this.selectedElement.uid, value: false });
        });
      },

      // Duplicated: store/customTheme - changeThemeVersionForElement
      // TODO: use a single method
      changeThemeVersion(newValue) {
        const versionName = newValue.key;
        const defaultStyle = this.themeDefaults.find((e) => e.name === versionName);
        if (defaultStyle) {
          const currentHtml = this.selectedElement?.data?.text;
          const styledHtml = defaultStyle?.data?.data?.text;

          const { name, customId } = this.selectedElement?.data?.form?.customSettings ?? {};
          const data = merge(this.selectedElement.data, defaultStyle.data.data);
          const customSettings = defaultStyle?.data?.data?.form?.customSettings;
          if (customSettings) {
            customSettings.customId = customId ?? customSettings.customId;
            customSettings.name = name ?? customSettings.name;
            customSettings.triggeredByCustomTheme = true;
            data.form.customSettings = customSettings;
          }
          const desktop = merge(this.selectedElement.desktop, defaultStyle.data.desktop);
          const mobile = merge(this.selectedElement.mobile, defaultStyle.data.mobile);
          this.setValueOf('selectedElement.data', data);
          this.setValueOf('selectedElement.desktop', desktop);
          this.setValueOf('selectedElement.mobile', mobile);

          if (currentHtml && styledHtml) {
            const newHtml = replaceQuillContent(currentHtml, styledHtml);
            this.selectedElement.data.text = newHtml;
            this.$bus.$emit('refreshWysiwyg', this.selectedElement.uid);
          }

          if (defaultStyle.data.subElements) {
            const subElements = merge(
              this.selectedElement.subElements,
              defaultStyle.data.subElements,
            );
            this.setValueOf('selectedElement.subElements', subElements);
          }
        }
        this.setValueOf('data.themeVersion', versionName);
        this.resetModification();
        track('apply-custom-theme', {
          name: versionName,
        });
      },
      notify(success) {
        this.$OmNotification({
          color: success ? 'success' : 'danger',
          type: 'full',
          width: 248,
          ignoreDuplicates: true,
          text: success
            ? this.$i18n.t(`customTheme.notifications.updatedSuccess`, {
                themeVersion: this.newStyleName,
                elementType: this.selectedElement.data.type,
              })
            : this.$i18n.t(`customTheme.notifications.updateFailure`, {
                themeVersion: this.newStyleName,
              }),
        });
      },
    },
  };
</script>
<style lang="sass" scoped>
  .button-holder
    button
      flex: 1
</style>
